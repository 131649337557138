.pageContainer {
    display: flex;
    height: 100vh;
    font-family:sans-serif;
  }
  
  .contentContainer {
    padding: 0px 20px;
    right: 0;
    width: 100%;
    margin: 10px auto;
    overflow-y: scroll;
  }
  
  .sideBar {
    width: 7%;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #005B60;
    transition: all 300ms linear;
    padding: 5px;
    margin: 10px;
    border-radius: 20px;
  }
  
  .sideBarOpen {
    width: 12.5%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    background-color: #005B60;
    transition: all 300ms linear;
    padding: 25px;
    margin: 10px;
    border-radius: 20px;
  
  }
  
 
  
  .logo {
    height: 40px;
    margin-top: 20px;
  }
  
  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    margin-top: 50px;
    gap: 20px;
  }
  
  .linksOpen {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    
  }
  
  .linkItem {
    font-weight: 300;
    color: white;
    text-decoration: none;
    /* border: 1px solid black; */
    border-radius: 10px;
    margin-bottom: 5px;
  }

  .linkItem :hover{
    /* color: #FF601C; */
    color:yellow;
  }

  .linkItemActive{
    background-color: #FF601C;
    font-weight: 300;
    color: white;
    text-decoration: none;
    /* border: 1px solid black; */
    border-radius: 10px;
    margin-bottom: 5px;
    /* width: 5rem; */
  }

  .linkItem ::selection{
    background-color: #FF601C;
  }
  
  .item_p {
    margin-left: 5px;
  }
  
  .linkItem:hover {
    color: #fff;
  }
  
  .sidebarIcon {
    height: 22px;
    object-fit: contain;
    /* color: #005B60; */
  }
  
  

  
  .linkItemActive .sidebarIcon {
    display: none;
  }
  
 
  
  .menuIcon {
    display: none;
  }
  
  @media only screen and (max-width: 1300px) {
    .contain {
      width: 95%;
    }
  
    .sideBar {
      display: none;
    }
  
    .menuIcon {
      display: grid;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .sideBar {
      display: none;
    }
  
    .contain {
      width: 95%;
    }
  
    .menuIcon {
      display: grid;
    }
  }